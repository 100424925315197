import BasicSection from "components/BasicSection";
import Layout from "components/Layout";
import { graphql, PageProps } from "gatsby";
import React from "react";
import "styles/about.css";
import greenEnteBurst from "images/about/green-ente-burst.svg";
import yellowStoryStar from "images/about/yellow-story-star.svg";
import Globe_icon from "images/about/Globe_icon.svg"
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import {
    SiDribbble,
    SiGithub,
    SiInstagram,
    SiLinkedin,
    SiX,
} from "@icons-pack/react-simple-icons";
import ExternalIconLink from "components/links/ExternalIconLink";
import { communityIcons } from "images/icons/community-icons";
import ducky from "images/about/ducky-about.svg";
import arrowUpRight from "images/icons/arrow-up-right.svg";
import RiveComponent, { RuntimeLoader } from "@rive-app/react-canvas";

interface DataProps {
    allTeamJson: {
        nodes: TeamPerson[];
    };
    allContributorsJson: {
        nodes: Contributor[];
    };
    allCommunityJson: {
        nodes: Community[];
    };
}

RuntimeLoader.setWasmUrl("/assets/rive/rive.wasm");

const About: React.FC<PageProps<DataProps>> = ({ path, data }) => {
    const team = data.allTeamJson.nodes;
    const contributors = data.allContributorsJson.nodes;
    const communities = data.allCommunityJson.nodes;

    return (
        <Layout title="Ente - About" path={path}>
            <div className="about-hero-desktop">
                <div className="about-hero-desktop-content">
                    <div>
                        <span className="about-hero-desktop-title">
                            We are Ente
                            <img
                                src={greenEnteBurst}
                                alt="Burst illustration"
                                className="about-hero-desktop-title-e-burst"
                                width={"30px"}
                                height={"30px"}
                            />
                        </span>
                    </div>
                    <div className="about-hero-desktop-description">
                        We help you preserve your memories with pixel perfect
                        privacy.
                    </div>
                </div>
                <RiveComponent
                    src="/assets/about-us-desktop.riv"
                    artboard="Desktop"
                    stateMachines="Desktop"
                    className="about-rive-desktop"
                />
            </div>
            <div className="about-hero-mobile">
                <RiveComponent
                    src="/assets/about-us-mobile.riv"
                    artboard="Mobile"
                    stateMachines="Mobile"
                    className="about-rive-mobile"
                />
                <div className="about-hero-mobile-content">
                    <div>
                        <span className="about-hero-mobile-title">
                            We are Ente
                            <img
                                src={greenEnteBurst}
                                alt="Burst illustration"
                                className="about-hero-mobile-title-e-burst"
                                width={"30px"}
                                height={"30px"}
                            />
                        </span>
                    </div>
                    <div className="about-hero-mobile-description">
                        We help you preserve your memories with pixel perfect
                        privacy.
                    </div>
                </div>
            </div>
            <OurStory />
            <Team {...{ team, contributors, communities }} />
        </Layout>
    );
};

export default About;

// Explicitly set the image format to PNG, otherwise the plugin creates a
// degraded quality JPG (not needed by us, the original images are small)
export const query = graphql`
    query {
        allTeamJson(filter: { section: { eq: "team" } }) {
            nodes {
                name
                photo {
                    childImageSharp {
                        gatsbyImageData(width: 180, formats: [PNG])
                    }
                }
                section
                github
                twitter
                linkedin
                instagram
                dribbble
                website
                designation
                description
            }
        }
        allContributorsJson(filter: { section: { eq: "contributors" } }) {
            nodes {
                name
                photo {
                    childImageSharp {
                        gatsbyImageData(width: 180, formats: [PNG])
                    }
                }
                section
            }
        }
        allCommunityJson(filter: { section: { eq: "communities" } }) {
            nodes {
                name
                community_link
                section
            }
        }
    }
`;

const OurStory: React.FC = () => {
    return (
        <BasicSection className="about-story-wrapper">
            <BasicSection.Header>
                <BasicSection.Heading>
                    <span className="about-story-title">
                        <span className="about-story-title-content">
                            Our story
                        </span>
                        <img
                            src={yellowStoryStar}
                            alt="Start illustration"
                            className="about-story-title-star"
                            width={"60px"}
                            height={"60px"}
                        />
                    </span>
                </BasicSection.Heading>
            </BasicSection.Header>
            <div className="about-our-story-hero">
                <div className="about-our-story-hero-inner">
                    <img className="duck" src={ducky} />
                    <div className="story">
                        <p>
                            Ente was born out of a need to preserve our personal
                            memories. Mainstream services did not respect our
                            privacy, and the rest lacked posterity.
                        </p>
                        <p>
                            So we started building an{" "}
                            <a
                                href="https://github.com/ente-io/ente"
                                target="_blank"
                                rel="noopener"
                            >
                                open source
                            </a>{" "}
                            company in 2020 - one that could outlive its people.
                        </p>
                        <p>
                            Ente is now a sustainable business funded by
                            customer subscriptions and backed by a{" "}
                            <a href="#community">wholesome community</a>.
                        </p>
                    </div>
                </div>
            </div>
        </BasicSection>
    );
};

interface TeamProps {
    team: TeamPerson[];
    contributors: Contributor[];
    communities: Community[];
}

export interface TeamPerson {
    name: string;
    section: "team";
    photo: ImageDataLike;
    github?: string;
    twitter?: string;
    linkedin?: string;
    instagram?: string;
    dribbble?: string;
    website?: string;
    designation: string;
    description: string;
}

export interface Contributor {
    name: string;
    section: "contributors";
    photo: ImageDataLike;
}

export interface Community {
    name: string;
    section: "communities";
    community_link: string;
}

interface TeamPersonProps {
    person: TeamPerson;
    index: number;
}

interface ContributorProps {
    person: Contributor;
    index: number;
}

interface CommunityProps {
    community: Community;
    index: number;
}

const Team: React.FC<TeamProps> = ({ team, contributors, communities }) => {
    return (
        <>
            <BasicSection className="about-team-members-wrapper" id="team">
                <BasicSection.Header>
                    <BasicSection.Heading>Team</BasicSection.Heading>
                </BasicSection.Header>
                <div className="about-team-members-container">
                    {team.map((person, index) => (
                        <div
                            key={index}
                            data-sal="fade"
                            data-sal-duration="1000"
                        >
                            <TeamMemberCard {...{ person, index }} />
                        </div>
                    ))}
                </div>
            </BasicSection>
            <BasicSection
                className="about-contributors-wrapper"
                id="contributors"
            >
                <BasicSection.Header>
                    <BasicSection.Heading>Contributors</BasicSection.Heading>
                </BasicSection.Header>
                <div className="about-contributors-container">
                    {contributors.map((person, index) => (
                        <div
                            key={index}
                            data-sal="fade"
                            data-sal-duration="1000"
                        >
                            <ContributorCard {...{ person, index }} />
                        </div>
                    ))}
                </div>
            </BasicSection>
            <BasicSection className="about-community-wrapper" id="community">
                <BasicSection.Header>
                    <BasicSection.Heading>Community</BasicSection.Heading>
                </BasicSection.Header>
                <div className="about-community-container">
                    {communities.map((community, index) => (
                        <div
                            key={index}
                            data-sal="fade"
                            data-sal-duration="1000"
                        >
                            <CommunityCard {...{ community, index }} />
                        </div>
                    ))}
                </div>
            </BasicSection>
        </>
    );
};

const TeamMemberCard: React.FC<TeamPersonProps> = ({ person, index }) => {
    const { name, photo, description, designation } = person;
    const image = getImage(photo!);

    return (
        <div className="about-team-member-card">
            <div>
                {image && (
                    <div className="circle-image">
                        <GatsbyImage image={image} className="pic" alt={name} />
                    </div>
                )}
                <span className="name">{name}</span>
                <span className="designation">{designation}</span>
            </div>
            <div className="caption">{description}</div>
            <div className="socials">
                <PersonSocials {...{ person }} />
            </div>
        </div>
    );
};

interface PersonDescriptionProps {
    person: TeamPerson;
}

const PersonSocials: React.FC<PersonDescriptionProps> = ({ person }) => {
    const { github, twitter, linkedin, instagram, website, dribbble } = person;
    const iconWidth = 20;

    return (
        <div
            className={`d-flex gap-2 justify-content-center justify-content-sm-end`}
        >
            {github && (
                <ExternalIconLink
                    href={`https://github.com/${github}`}
                    title="GitHub"
                >
                    <SiGithub width={iconWidth} />
                </ExternalIconLink>
            )}

            {twitter && (
                <ExternalIconLink
                    href={`https://twitter.com/${twitter}`}
                    title="X (Formerly Twitter)"
                >
                    <SiX width={iconWidth} />
                </ExternalIconLink>
            )}

            {linkedin && (
                <ExternalIconLink
                    href={`https://linkedin.com/in/${linkedin}`}
                    title="LinkedIn"
                >
                    <SiLinkedin width={iconWidth} />
                </ExternalIconLink>
            )}

            {instagram && (
                <ExternalIconLink
                    href={`https://instagram.com/${instagram}`}
                    title="Instagram"
                >
                    <SiInstagram width={iconWidth} />
                </ExternalIconLink>
            )}

            {dribbble && (
                <ExternalIconLink
                    href={`https://dribbble.com/${dribbble}`}
                    title="Dribbble"
                >
                    <SiDribbble width={iconWidth} />
                </ExternalIconLink>
            )}
            {website && (
                <ExternalIconLink
                    href={`${website}`}
                    title="website"
                >
                    <img src={Globe_icon} width={iconWidth}/>
                </ExternalIconLink>
            )}
        </div>
    );
};

const ContributorCard: React.FC<ContributorProps> = ({ person, index }) => {
    const { name, photo } = person;
    const image = getImage(photo!);

    return (
        <div className="about-contributor-card">
            {image && <GatsbyImage image={image} className="pic" alt={name} />}
            <span className="name">{name}</span>
        </div>
    );
};

const CommunityCard: React.FC<CommunityProps> = ({ community, index }) => {
    const { name, community_link } = community;

    return (
        <div className="about-contributor-card community">
            <a href={community_link} target="_blank" className="community-link">
                <span
                    className={`community-icon ${name === "Mastodon" ? "community-icon-mastodon" : ""}`}
                >
                    {communityIcons.filter((a) => a.id === name)[0].icon}
                </span>
                <span className="community-name">
                    <span>{name}</span>
                    <img src={arrowUpRight} />
                </span>
            </a>
        </div>
    );
};
